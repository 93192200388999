<template>
  <b-card no-body class="member-card">
    <b-row no-gutters class="h-100">
      <b-col cols="4">
        <b-card-img :src="img" :alt="alt" class="portrait"></b-card-img>
      </b-col>
      <b-col cols="8" class="px-3">
        <div class="text-uppercase job-title i-font-header i-primary mt-4 mt-lg-5">
          {{header}}
        </div>
        <div class="text-uppercase i-text-smaller i-font-text-bold i-secondary mt-1 mb-2">
          {{role}}
        </div>
        <div class="i-text-small mt-3 i-font-text-light">
          {{description}}
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    header: String,
    role: String,
    description: String,
    alt: String,
    img: String
  }
}
</script>

<style lang="sass" scoped>
.member-card
  box-shadow: 0px 0px 24px 0px #E3272614!important
  border-radius: 50px!important
  border: 1px solid $primary-color!important
  overflow: hidden!important
  height: 175px
  @media screen and (min-width: $lg)
    height: 210px

.portrait
  height: 100%
  object-fit: cover
  object-position: bottom
  //border-radius: 50px 0 0 50px
.job-title
  font-size: 24px
  line-height: 90%
  @media screen and (min-width: $sm)
    font-size: 26px

</style>
