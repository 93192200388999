<template>
  <page-skeleton theme="light" :steps="false">
    <section-half-image
        hero
        arrow
        scroll-to="about-arrow"
        image-path="https://ik.imagekit.io/isotronic/about/30-years_bQPeNkj5Sp.png?updatedAt=1635421418775"
        :alt="$t('about.hero.alt')"
    >
      <template #header>
        {{ $t('about.hero.header') }}
      </template>
      <template #content>
        {{ $t('about.hero.text') }}
      </template>
    </section-half-image>
    <section-half-image
        id="about-arrow"
        photo-right-align
        image-path="https://ik.imagekit.io/isotronic/about/retro_software_PKfrnaxdBW.png?updatedAt=1635421559454"
        :alt="$t('about.section1.alt')"
        small-photo
        background="light"
    >
      <template #header>
        {{ $t('about.section1.header') }}
      </template>
      <template #content>
        <i18n path="about.section1.text" tag="p">
          <template v-slot:space>
            <div class="my-3"></div>
          </template>
        </i18n>
      </template>
    </section-half-image>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/about/team_meeting_STpPlnzzTag.jpg?updatedAt=1635421605084"
        :alt="$t('about.fullImg.alt1')"
    ></full-width-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/about/line_of_vials_fQc9HOmCZA.jpg?updatedAt=1635422461465"
        :alt="$t('about.section2.alt')"
        background="light"
        align="bl"
    >
      <template #header>
        {{ $t('about.section2.header') }}
      </template>
      <template #content>
        <i18n path="about.section2.text" tag="p">
          <template v-slot:space>
            <div class="my-3"></div>
          </template>
        </i18n>
      </template>
    </section-half-image>
    <div class="img-wrapper i-border">
      <div class="img-container">
        <b-img-lazy src="https://ik.imagekit.io/isotronic/about/blue_vial_production_line_1zXzx89kaE.jpg?updatedAt=1635422483780"
                    :alt="$t('about.section3.alt')"
                    class="full-img w-100"/>
        <b-card class="quote-card p-2 p-md-3">
          <b-row>
            <b-col cols="12">
              <i18n path="about.section3.text" tag="p" class="i-primary quote-font i-font-header">
                <template v-slot:space>
                  <div class="my-3"></div>
                </template>
                <template v-slot:quote>
                  <span>"</span>
                </template>
              </i18n>
            </b-col>
            <b-col cols="12" class="text-right mt-4">
              {{ $t('about.section3.source') }}
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <about-team />
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/about/team_photo_QylG0dvDfw.jpg?updatedAt=1635422543920"
        :alt="$t('about.fullImg.alt2')"
    ></full-width-image>

  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";
import AboutTeam from "@/components/about/AboutTeam"
export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage,
    AboutTeam
  },
  metaInfo() {
    return {
      title: this.$t('about.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('about.meta.description')}
      ]
    }
  },
}
</script>

<style lang="sass" scoped>
.quote-font
  font-size: 18px
  @media screen and (min-width: $sm)
    font-size: 20px
  @media screen and (min-width: $md)
    font-size: 24px
  @media screen and (min-width: $xl)
    font-size: 28px

.quote-card
  position: absolute
  top: 0%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 100
  border-radius: 20px
  opacity: 0.9
  width: 90%
  @media screen and (min-width: $sm)
    width: 75%
  @media screen and (min-width: $md)
    width: 65%
  @media screen and (min-width: $lg)
    width: 50%
  @media screen and (min-width: $lg)
    width: 40%

.full-img
  height: 500px
  object-position: top right
  object-fit: cover !important
  display: block
  transform: translateY(-50%)
  @media screen and (min-width: $sm)
    height: auto !important
    max-height: 520px
    width: 100% !important

.img-container
  max-height: inherit
  transform: translateY(50%)

.img-wrapper
  height: auto
  max-height: 500px
  overflow: hidden
  @media screen and (min-width: $md)
    max-height: 520px
  @media screen and (min-width: $lg)
    max-height: 812px
  @media screen and (min-width: $xl)
    max-height: 850px
    width: 1920px
    margin-left: auto
    margin-right: auto



</style>
