<template>
  <b-container fluid class="i-skeleton i-border about-team">
    <b-row class="justify-content-center i-vertical-no-photo">
      <b-col cols="12" class="text-left text-sm-center">
        <h2 class="text-uppercase">
          {{ $t('about.section4.header') }}
        </h2>
        <div class="mt-2 mb-4 i-font-text-light i-secondary">
          {{ $t('about.section4.subheader') }}
        </div>
      </b-col>
      <b-col cols="12" md="10" lg="9" xl="7">
        <b-row>
          <b-col v-for="(member, index) in members"
                 :key="index + 'member'"
                 cols="12" sm="6"
                 class="my-2 my-sm-3"
          >
            <about-team-card
                :alt="member.alt"
                :header="member.header"
                :role="member.role"
                :description="member.description"
                :img="member.photo"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AboutTeamCard from "@/components/about/AboutTeamCard"

export default {
  components: {
    AboutTeamCard
  },
  data() {
    return {
      members: [
        {
          header: this.$t('about.section4.gregor.header'),
          role: this.$t('about.section4.gregor.role'),
          description: this.$t('about.section4.gregor.description'),
          alt: this.$t('about.section4.gregor.alt'),
          photo: "https://ik.imagekit.io/isotronic/about/portraits/gregor_crop_VhN0Mk7i6_.jpg?updatedAt=1635422714943"
        },
        {
          header: this.$t('about.section4.vale.header'),
          role: this.$t('about.section4.vale.role'),
          description: this.$t('about.section4.vale.description'),
          alt: this.$t('about.section4.vale.alt'),
          photo: "https://ik.imagekit.io/isotronic/about/portraits/vale_crop_0yRKnQPEm9.jpg?updatedAt=1635422714724"
        },
        {
          header: this.$t('about.section4.viktor.header'),
          role: this.$t('about.section4.viktor.role'),
          description: this.$t('about.section4.viktor.description'),
          alt: this.$t('about.section4.viktor.alt'),
          photo: "https://ik.imagekit.io/isotronic/about/portraits/viktor_crop_dheyFh_ltB.png?updatedAt=1635422714716"
        },
        {
          header: this.$t('about.section4.alex.header'),
          role: this.$t('about.section4.alex.role'),
          description: this.$t('about.section4.alex.description'),
          alt: this.$t('about.section4.alex.alt'),
          photo: "https://ik.imagekit.io/isotronic/about/portraits/alex_crop_YHC66sHKAGC.jpg?updatedAt=1635422715117"
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.about-team
  background: rgba(100, 101, 104, 0.05)
</style>
